export const jobData= [
  {
    id: 0,
    jobName: 'AI工程师',
    allJobs: [
      {
        name: 'AI工程师',
        city: '泉州',
        job: '人工智能',
        description: [
          '1、本科及以上学历，相关专业硕士优先',
          '2、具备自然语言处理、语音、图像视频及机器学习方面的基础理论知识，有两年以上相关领域工作经验优先；',
          '3、熟练使用 TensorFlow/MxNet/Caffe/飞浆等常用深度学习框架之一，能够独立处理不同模型直接的转换；',
          '4、能够独立收集公开信息进行大规模训练并对模型进行调优、量化与压缩；',
          '5、对自然语音处理、知识图谱、智能对话、情感分析、图像分类检测、语音识别等某一领域算法有深刻的了解，有实际项目经验的优先；',
          '6、优秀的学习能力与创新能力，具有良好的沟通协调和团队合作能力；',
          '7、负责人工智能研发管理及技术产品化应用，包括但不限于自然语言NLP处理、信息抽取、智能问答、知识图谱、语义理解、意图识别, 机器翻译等；',
          '8、负责项目开发团队的管理与指导，项目交付进度与质量把控；',
          '9、负责产品开发过程中瓶颈问题解决与技术攻关，沟通与协调；',
          '10、负责技术发展趋势洞察与预研，分析业务应用场景，搭建人工智能的平台化应用。'
        ],
        requirements: [
          '1、本科及以上学历，相关专业硕士优先；',
          '2、具备自然语言处理及机器学习方面的基础理论知识，有两年以上NLP相关工作经验；',
          '3、熟练掌握C，C++，Python，OpenGL等语言中的一种或多种，有开源人工智能平台应用/开发经验者优先；',
          '4、熟练使用TensorFlow/MxNext/Caffe 或其他常用深度学习框架，对中文分词、主题分析、智能对话、情感分析、图像分析、计算机视觉等方面算法有深刻的了解，有实际项目经验的优先；',
          '5、优秀的学习能力与创新能力；',
          '6、具有良好的沟通协调和团队合作能力。'
        ]
      }
    ]
  },
  {
    id: 1,
    jobName: 'iOS工程师',
    allJobs: [
      {
        name: 'iOS工程师',
        city: '泉州',
        job: 'iOS开发',
        description: [
          '1、根据需求在iOS移动端进行APP的设计与开发；',
          '2、参与项目的产品设计、分析、开发以及维护；',
          '3、参与移动端的接口开发，架构设计；',
          '4、独立完成开发工作，进行模块测试，保证代码的质量；',
          '5、将学习和研究到新的移动互联网技术应用到产品中进行创新；',
          '6、修正并验证测试中发现的问题；',
          '7、可以保证产品的正常运行以及维护升级。'
        ],
        requirements: [
          '1、精通ios平台，经历过完整的app开发流程，3年以上IOS开发经验；',
          '2、精通ios SDK中的UI、网络、数据库、XML/JSON解析/Js脚本等开发技巧，并熟悉夸平台APP开发；',
          '3、对代码具有良好的理解，可以编写结构良好的代码，具有良好的规范；',
          '4、富有激情和良好的责任心，认真严谨的工作态度，有良好的团队协作，善于沟通和协作；有超强的学习能力，清晰的思维，能独立分析和解决问题；',
          '5、有良好的逻辑思维，可以指引他人工作。'
        ]
      }
    ]
  },
  {
    id: 2,
    jobName: '安卓工程师',
    allJobs: [
      {
        name: '安卓工程师',
        city: '泉州',
        job: 'Android开发',
        description: [
          '1、参与APP服务端系统分析、设计、开发、管理；',
          '2、参与项目的产品设计、分析、开发以及维护；',
          '3、参与移动端的接口开发，架构设计；',
          '4、根据产品的要求，参与单元模块的设计、编码、测试。'
        ],
        requirements: [
          '1、熟悉Android应用开发框架，至少独立开发过3个以上的APP；',
          '2、有较好的优化、调试和解决问题能力，逻辑思维清晰严谨，有良好的编码习惯，对性能优化和内存优化有一定了解；',
          '3、掌握碎片化问题的解决方法；',
          '4、掌握XML的解析及JSON解析，JS，有夸平台APP开发的优先；',
          '5、熟练掌握SQLite数据库、Shared Preferences以及文件存储等存储方式；',
          '6、掌握多线程、熟悉http，SOCKET等协议；',
          '7、工作态度严谨，思维敏捷，做事灵活，良好的团队合作精神，有强烈的工作责任感和工作激情；',
          '8、熟悉常用软件架构模式，熟悉算法与数据结构，多线程，网络编程等；',
          '9、有混合开发经验者优先；熟悉JNI/NDK开发者优先。'
        ]
      }
    ]
  },
  {
    id: 3,
    jobName: 'Java工程师',
    allJobs: [
      {
        name: '初中级Java工程师',
        city: '泉州',
        job: 'Java后端开发',
        description: [
          '1、根据需求进行软件设计、程序编码、单元测试、实施工作及相关说明文档的编写；',
          '2、协助测试人员完成软件系统及模块的测试；',
          '3、负责相关技术难点问题的研究，完成相关技术文档的编写工作。'
        ],
        requirements: [
          '1、计算机或相关专业专科及以上学历，3年及以上Java软件开发经验；',
          '2、熟练使用Java语言，掌握Spring、SpringMVC、SpringBoot、SpringCloud等Java主流框架；熟悉Mysql数据库；',
          '3、熟练掌握JavaScript/Ajax开发；',
          '4、具有良好的团队合作精神和积极学习的态度，能承担一定的工作压力。'
        ]
      },
      {
        name: 'Java工程师',
        city: '泉州',
        job: 'Java后端开发',
        description: [
          '1、IM服务端的设计和开发及其持续的优化；',
          '2、参与产品需求的沟通、讨论和协调；',
          '3、制定功能需求的技术实现方案；',
          '4、负责通信接口的设计和开发。'
        ],
        requirements: [
          '1、精通Java,特别是Java多线程、通信编程；',
          '2、5年以上socket开发，有粘包、拆包、编解码、future机制、乱序等问题的处理经验，具有IM系统的研发经验；',
          '3、高可用、高并发的IM集群设计方案；',
          '4、常用中间件的开发、优化、维护工作，包括zookeeper,redis,mq（rabbitmq\rocketmq\activemq之一）,kafka等；',
          '5、有互联网即时通讯软件开发经验者优先；',
          '6、有消息队列处理经验，有分布式处理经验优先；',
          '7、良好的团队沟通、协调能力，可承受一定的工作压力。'
        ]
      }
    ]
  },
  {
    id: 4,
    jobName: '测试',
    allJobs: [
      {
        name: '性能测试工程师',
        city: '泉州',
        job: '软件测试',
        description: [
          '1、主要负责服务端及中间件的性能测试工作；',
          '2、收集、分析和挖掘性能测试需求并进行梳理；',
          '3、评估和跟进性能测试需求、输出测试计划、测试方案；',
          '4、构造性能测试需求相关的测试数据，开发对应的性能测试脚本；',
          '5、能够精准定位性能问题，提供有价值的性能优化建议；',
          '6、控制项目测试进度并能有效规避风险，保持与项目组顺畅沟通并汇报测试进度；',
          '7、制定并推行性能测试相关的规范与流程，制定合理的测试策略和测试架构，保证测试工作高效有 序的进行。'
        ],
        requirements: [
          '1、5年以上性能测试经验；',
          '2、熟练使用Jmeter/LoadRunner等测试工具，熟悉常用的性能测试监控工具，熟悉性能指标分析；',
          '3、熟悉JAVA或其它后端编程语言，熟悉Linux操作系统；',
          '4、熟悉Mysql/Redis/mongoDB数据库及中间件的使用；',
          '5、具备分布式系统的性能/稳定性测试经验，能够独立负责性能测试。'
        ]
      },
      {
        name: '功能测试工程师',
        city: '泉州',
        job: '软件测试',
        description: [
          '1、负责移动App端、管理后台的功能测试、接口测试、兼容性测试等；',
          '2、参与项目组的需求分析、关注需求的可测性及合理性；',
          '3、独立制定测试方案、设计测试用例、执行测试用例；',
          '4、发现问题并能够分析定位问题，与开发团队进行有效沟通、推动问题及时解决；',
          '5、收集测试数据分析测试质量、总结、评估风险，持续改进提升测试效率；',
          '6、编写测试报告、用户手册等文档。'
        ],
        requirements: [
          '1、大专学历，计算机、通信、电子等相关专业优先；',
          '2、熟悉测试理论相关知识、通信知识；',
          '3、具备2年以上的软件测试或开发经验，可独立负责项目测试工作；',
          '4、良好的沟通表达能力、诚实、敬业、工作积极主动、踏实心细，吃苦耐劳，抗压能力较强，有较强的责任心,具有团队精神。；',
          '5、具备专项测试领域技能的优先（如自动化、性能、安全测试等）'
        ]
      }
    ]
  }
]
