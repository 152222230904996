// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

import * as API from './hugouJson.js'
Vue.prototype.$api = API
import * as JOINAPI from './joinusJson.js'
Vue.prototype.$joinapi = JOINAPI

import '@/assets/styles/main.scss'

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: (h) => h(App),
}).$mount('#app')
